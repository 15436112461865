<template>
  <div class="charging-point-availability-edit" :class="{ paused }">
    <b-form-row v-for="(value, key) in availability.days" :key="key">
      <b-col class="d-flex flex-wrap">
        <b-form-group :label="getDayLabel(key)" class="activation-switch">
          <b-form-radio-group
            v-model="value.available"
            :options="[
              { text: 'ON', value: true },
              { text: 'OFF', value: false }
            ]"
            buttons
            :button-variant="value.available ? 'dark' : 'secondary'"
            size="sm"
          />
        </b-form-group>
        <div class="d-flex flex-wrap">
          <b-form-group label="DÉBUT" class="time-picker">
            <b-form-input
              type="time"
              v-model="value.startTime"
              min="00:00"
              :max="value.endTime"
              :required="value.available"
              :disabled="!value.available"
              class="btn btn-light"
            />
          </b-form-group>
          <b-form-group label="FIN" class="time-picker">
            <b-form-input
              type="time"
              v-model="value.endTime"
              :min="value.startTime"
              max="23:59"
              :required="value.available"
              :disabled="!value.available"
              class="btn btn-light"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-form-row>
    <div class="overlay"></div>
  </div>
</template>

<script>
import { ChargingPointAvailabilityMixin } from '@/mixins/cp-availability'

export default {
  name: 'charging-point-availabilty-edit',
  props: {
    availability: { type: Object, required: true },
    paused: { type: Boolean }
  },
  mixins: [ChargingPointAvailabilityMixin]
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

.charging-point-availability-edit {
  .form-group {
    margin-left: 20px;
  }

  .activation-switch {
    align-self: flex-end;

    & /deep/ .btn-group-toggle {
      background-color: #fff;
      border-radius: 5px;
      //width: 67px;

      .btn-dark,
      .btn-secondary {
        #app & {
          width: 34px;
          padding: 0;
          text-align: center;
        }
      }

      .btn-secondary {
        #app & {
          background-color: #e5e5e5;
          color: #a8a8a8;
        }
      }

      .btn-dark,
      .btn-secondary {
        &:not(.active) {
          #app & {
            background-color: transparent;
            color: #f8f8f8;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            cursor: pointer;
          }
        }
      }
    }
  }

  .time-picker {
    & /deep/ legend {
      #app & {
        font-weight: $font-weight-bold;
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0;
        color: #a0a0a0;
      }
    }

    input {
      #app & {
        width: 98px;
        font-size: 16px;
        line-height: 48px;

        &:disabled,
        &.disabled {
          background-color: #e5e5e5;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.22);
          color: #a8a8a8;
        }
      }
    }
  }

  .overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &.paused {
    opacity: 0.8;

    .overlay {
      display: block;
    }
  }
}
</style>
