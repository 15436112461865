var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "charging-point-availability-edit",
      class: { paused: _vm.paused }
    },
    [
      _vm._l(_vm.availability.days, function(value, key) {
        return _c(
          "b-form-row",
          { key: key },
          [
            _c(
              "b-col",
              { staticClass: "d-flex flex-wrap" },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "activation-switch",
                    attrs: { label: _vm.getDayLabel(key) }
                  },
                  [
                    _c("b-form-radio-group", {
                      attrs: {
                        options: [
                          { text: "ON", value: true },
                          { text: "OFF", value: false }
                        ],
                        buttons: "",
                        "button-variant": value.available
                          ? "dark"
                          : "secondary",
                        size: "sm"
                      },
                      model: {
                        value: value.available,
                        callback: function($$v) {
                          _vm.$set(value, "available", $$v)
                        },
                        expression: "value.available"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap" },
                  [
                    _c(
                      "b-form-group",
                      { staticClass: "time-picker", attrs: { label: "DÉBUT" } },
                      [
                        _c("b-form-input", {
                          staticClass: "btn btn-light",
                          attrs: {
                            type: "time",
                            min: "00:00",
                            max: value.endTime,
                            required: value.available,
                            disabled: !value.available
                          },
                          model: {
                            value: value.startTime,
                            callback: function($$v) {
                              _vm.$set(value, "startTime", $$v)
                            },
                            expression: "value.startTime"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      { staticClass: "time-picker", attrs: { label: "FIN" } },
                      [
                        _c("b-form-input", {
                          staticClass: "btn btn-light",
                          attrs: {
                            type: "time",
                            min: value.startTime,
                            max: "23:59",
                            required: value.available,
                            disabled: !value.available
                          },
                          model: {
                            value: value.endTime,
                            callback: function($$v) {
                              _vm.$set(value, "endTime", $$v)
                            },
                            expression: "value.endTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c("div", { staticClass: "overlay" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }